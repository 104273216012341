<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title class="mb-2">
      <h4 class="dxa_modal_title h4">{{ cardTitle }}</h4>
    </v-card-title>
    <v-card-text data-test="VehicleRequest:AddUserInterestModal:form">
      <v-form @submit.stop.prevent="submit()" ref="form">
        <v-combobox
          data-test="Vehicle:AddUserInterestModal:SelectUser"
          :disabled="edit"
          v-model="userSelected"
          :items="users"
          :rules="[required]"
          item-text="Email"
          item-value="Id"
          :label="$tc('user', 1)"
          @change="handleChangeUser"
        ></v-combobox>

        <v-combobox
          data-test="Vehicle:AddUserInterestModal:SelectCompany"
          :disabled="edit"
          v-model="companySelected"
          :items="companies"
          :rules="[required]"
          item-text="Name"
          item-value="Id"
          :label="$tc('company', 1)"
        ></v-combobox>

        <v-combobox
          data-test="Vehicle:AddUserInterestModal:SelectVehicle"
          v-model="vehicleSelect"
          :items="vehicles.CompanyInvestmentVehicles"
          item-text="InvestmentVehicle.Name"
          item-value="InvestmentVehicle.Id"
          :label="$t('vehicle_title_single')"
        ></v-combobox>

        <v-combobox
          data-test="Vehicle:AddUserInterestModal:SelectStatus"
          :disabled="edit"
          v-model="statusSelected"
          :items="StatusOptions"
          :rules="[requiredNumber]"
          item-text="Text"
          item-value="Value"
          :label="$t('status')"
        ></v-combobox>

        <v-select
          data-test="Vehicle:AddUserInterestModal:InvestIntended"
          :rules="[required]"
          v-model="interest.InvestorInvestIntendedPersonId"
          :items="investedIntendedOptions"
          item-text="tradingName"
          item-value="id"
          :label="$t('investment_desire')"
          dense
        ></v-select>

        <v-select
          :rules="[required]"
          v-model="interest.Currency"
          :items="currencyOptions"
          item-text="text"
          item-value="value"
          :label="$t('currency')"
          dense
          data-test="Vehicle:AddUserInterestModal:Currency"
        ></v-select>

        <v-text-field
          data-test="Vehicle:AddUserInterestModal:SelectValue"
          :rules="[required]"
          v-model="interest.Value"
          :label="$t('value')"
          class="pt-0"
          :prefix="getCurrencyPrefixData(interest.Currency)"
          type="number"
        />

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            data-test="Vehicle:AddUserInterestModal:BtnSave"
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close', null)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-alert
        class="ma-2"
        v-if="error"
        type="error"
        data-test="Vehicle:AddUserInterestModal:Error"
        >{{ $t(error) }}</v-alert
      >
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getCurrencyPrefix } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import GeneralServices from "@/services/GeneralServices";
import { InvestIntendedPersonTypeEnum } from "@/shared/enums/InvestIntendedPersonTypeEnum";
import { removeFormatCnpj, formatCnpj } from "@/shared/helpers/cnpjHelper";

export default {
  name: "AddInterestModal",
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    existing_user: true,
    row: null,
    edit: false,
    users: [],
    companies: [],
    vehicles: [],
    error: null,
    userSelected: null,
    formatCnpj,
    statusSelected: null,
    companySelected: null,
    stock_type: null,
    vehicleSelect: null,
    getCurrencyPrefixData: getCurrencyPrefix,
    InvestIntendedPersonTypeEnum,
    investedIntendedOptions: [],
    investedIntendedSelected: null,
    interest: {
      UserId: null,
      Value: null,
      InvestmentVehicleId: null,
      Status: null,
      CompanyId: null,
      Currency: null,
      InvestorInvestIntendedPersonId: null,
    },
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
  }),
  props: {
    Interest: Object,
  },
  watch: {
    companySelected() {
      this.getVehicleAccordingCompany();
    },
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    requiredNumber() {
      return (value) => value != null || this.$t("required");
    },
    StatusOptions() {
      return [
        { Text: this.$tc("interest_status_pending", 1), Value: 0 },
        { Text: this.$tc("interest_status_approved", 1), Value: 1 },
        { Text: this.$tc("interest_status_declined", 1), Value: 2 },
        { Text: this.$t("interest_status_waiting_investor"), Value: 3 },
      ];
    },
    cardTitle() {
      if (this.Interest) {
        return this.$t("edit_new_allocation");
      } else {
        return this.$t("new_allocation");
      }
    },
  },
  async created() {
    if (this.Interest != null) {
      this.edit = true;

      this.apiService
        .getRequest(`company/list`)
        .then((resp) => {
          this.companies = JSON.parse(resp.message);
          this.loading = false;
          this.getVehicleAccordingCompany();
        })
        .catch((error) => {});

      await this.getInvestedPersonIntendedOptions(this.Interest.User.Id);
      this.interest = {
        Value: this.Interest.Value,
        Id: this.Interest.Id,
        InvestmentVehicleId: this.Interest.InvestmentVehicle
          ? this.Interest.InvestmentVehicle.Id
          : 0,
        Currency: this.Interest.Currency,
        InvestorInvestIntendedPersonId:
          this.Interest.InvestorInvestIntendedPersonId,
      };
      this.userSelected = this.Interest.User;
      this.companySelected = this.Interest.Company;
      this.statusSelected = this.Interest.Status;
      this.vehicleSelect = this.Interest.InvestmentVehicle
        ? this.Interest.InvestmentVehicle.Name
        : null;
    } else {
      this.edit = false;
      this.loading = true;
      this.apiService
        .getRequest(`investor/list`)
        .then((resp) => {
          this.users = JSON.parse(resp);
        })
        .catch((error) => {});
      this.apiService
        .getRequest(`company/list`)
        .then((resp) => {
          this.companies = JSON.parse(resp.message);
          this.loading = false;
        })
        .catch((error) => {});
    }
  },
  methods: {
    async handleChangeUser() {
      if (this.userSelected && this.userSelected.Id) {
        await this.getInvestedPersonIntendedOptions(this.userSelected.Id);
      }
    },
    async getInvestedPersonIntendedOptions(userId) {
      await this.apiService
        .getRequest(`investor/shareholder?investorId=${userId}`)
        .then(async (resp) => {
          const personPF = await resp.content.find((person) => {
            return (
              person.investIntendedPersonId ===
              this.InvestIntendedPersonTypeEnum.PF
            );
          });

          // só quando existe um PersonPF na array. criamos essa lógica para colocar o tradingName da pessoa física.
          if (personPF) {
            const personPFLabel = {
              id: personPF.id,
              investIntendedPersonId: personPF.investIntendedPersonId,
              tradingName: this.$t("fisical_person"),
            };

            // quando só vier pessoa física na array.
            if (resp && resp.content && resp.content.length === 1) {
              this.investedIntendedOptions = [personPFLabel];
              return;
            }

            // length > 1, ele adicionou algum CNPJ.
            if (resp && resp.content && resp.content.length > 1) {
              const onlyPj = resp.content.filter((person) => {
                let personObject = person;

                if (
                  person.investIntendedPersonId ===
                  this.InvestIntendedPersonTypeEnum.PJ
                ) {
                  if (!person.tradingName) {
                    personObject.tradingName = this.formatCnpj(
                      person.socialNumberOfInvestmentVehicle
                    );
                  }

                  return personObject;
                }
              });

              this.investedIntendedOptions = [personPFLabel, ...onlyPj];
            }
          }
          // se não tem PF, todos tem tradingName, então não precisa de tratamento.
          else {
            if (resp && resp.content && resp.content.length > 0) {
              this.investedIntendedOptions = resp.content.map((person) => {
                let personObject = person;

                if (!person.tradingName) {
                  personObject.tradingName = this.formatCnpj(
                    person.socialNumberOfInvestmentVehicle
                  );
                }

                return personObject;
              });
            }
          }
        })
        .catch((error) => {
          this.error = error.body.message;
        });
    },
    getVehicleAccordingCompany() {
      const selectCompanie = this.companySelected;

      const vehicleToCompanie = this.companies.filter((item) => {
        return item.CompanyId === selectCompanie.CompanyId;
      });

      if (vehicleToCompanie[0].CompanyInvestmentVehicles) {
        this.vehicles = vehicleToCompanie[0];
      } else {
        this.vehicles = [];
      }
    },
    submit: async function () {
      var result = this.$refs.form.validate();
      if (this.loading || !result) {
        return;
      }

      this.interest.Value = parseFloat(this.interest.Value);

      this.loading = true;
      this.error = null;
      if (this.edit) {
        if (this.vehicleSelect) {
          const vehicleEdited = typeof this.vehicleSelect;
          if (vehicleEdited !== "string") {
            this.interest.InvestmentVehicleId =
              this.vehicleSelect.InvestmentVehicle.Id;
          }
        }

        await this.apiService
          .postRequest("companyinterest/edit", this.interest)
          .then((result) => {
            this.$emit("update", this.interest);
            this.$emit("reload");
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      } else {
        this.interest.CompanyId = this.companySelected.CompanyId;
        this.interest.UserId = this.userSelected.Id;
        this.interest.Status = this.statusSelected.Value;
        this.vehicleSelect
          ? (this.interest.InvestmentVehicleId =
              this.vehicleSelect.InvestmentVehicle.Id)
          : (this.interest.InvestmentVehicleId = 0);

        await this.apiService
          .postRequest("companyinterest/new", this.interest)
          .then((result) => {
            this.$emit("update");
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      }
      this.loading = false;
    },
  },
};
</script>
